import { App } from '../../../shared/js/register.js';
import { start } from '../../../shared/js/init.js';

import translations from './translations.json';
import '../../../shared/js/phones.js';

document.addEventListener("DOMContentLoaded", function () {
    window.Form = new App({

        noResults: "\u0dc3\u0db8\u0dcf\u0db1\u0020\u0dad\u0ddc\u0dbb\u0dad\u0dd4\u0dbb\u0dd4\u0020\u0dc4\u0db8\u0dd4\u0020\u0db1\u0ddc\u0dc0\u0dd3\u0dba\u0020",
        messages: {
            required: '\u0d9a\u0dca\u0dc2\u0dda\u0dad\u0dca\u200d\u0dbb\u0dba\u0020\u0db4\u0dd4\u0dbb\u0dc0\u0db1\u0dca\u0db1\u0020',
            phone: {
                incorrect: '\u0daf\u0dd4\u0dbb\u0d9a\u0dae\u0db1\u0dba\u0020\u0d85\u0d82\u0d9a\u0dba\u0020\u0dc0\u0dd0\u0dbb\u0daf\u0dd2\u0dba\u0dd2'
            },
            email: {
                incorrect: '\u0dbd\u0dd2\u0db4\u0dd2\u0db1\u0dba\u0020\u0d86\u0d9a\u0dd8\u0dad\u0dd2\u0dba\u0020\u0dc0\u0dd0\u0dbb\u0daf\u0dd2\u0dba\u0dd2\u0020',
                symbolNotFound: '\u0dbd\u0dd2\u0db4\u0dd2\u0db1\u0dba\u0dd9\u0dc4\u0dd2\u0020 "@" \u0d85\u0d9a\u0dca\u0dc2\u0dbb\u0dba\u0020\u0d85\u0da9\u0d82\u0d9c\u0dd4\u0020\u0dc0\u0dd2\u0dba\u0020\u0dba\u0dd4\u0dad\u0dd4\u0dba\u002e\u0020\u0dbd\u0dd2\u0db4\u0dd2\u0db1\u0dba\u0dd9\u0dc4\u0dd2\u0020 "@" \u0020\u0dc3\u0d82\u0d9a\u0dda\u0dad\u0dba\u0020\u0db8\u0d9c\u0020\u0dc4\u0dd0\u0dbb\u0dd3\u0020\u0d87\u0dad',
                addressIsNotFull: '"@" \u0d85\u0d9a\u0dca\u0dc2\u0dbb\u0dba\u0da7\u0020\u0db4\u0dc3\u0dd4\u0dc0\u0020\u0dbd\u0dd2\u0db4\u0dd2\u0db1\u0dba\u0dda\u0020\u0d9a\u0ddc\u0da7\u0dc3\u0020\u0d87\u0dad\u0dd4\u0dbd\u0dca\u0020\u0d9a\u0dbb\u0db1\u0dca\u0db1\u002e\u0020\u0dbd\u0dd2\u0db4\u0dd2\u0db1\u0dba\u0020\u0dc3\u0db8\u0dca\u0db4\u0dd6\u0dbb\u0dca\u0dab\u0020\u0db1\u0dd0\u0dad\u002e'
            },
            password: {
                minLength: '\u0d85\u0dc0\u0dc3\u0dbb\u0020\u0daf\u0dd3\u0020\u0d87\u0dad\u0dd2\u0020\u0d85\u0dc0\u0db8\u0020\u0d85\u0d9a\u0dca\u0dc2\u0dbb\u0020\u0d9c\u0dab\u0db1 6. \u0daf\u0dd0\u0db1\u0dca %current_length%'
            },
            repeatPassword: {
                discrepancy: '\u0d87\u0dad\u0dd4\u0dc5\u0dad\u0dca\u0020\u0d9a\u0dc5\u0020\u0db8\u0dd4\u0dbb\u0db4\u0daf\u0020\u0db1\u0ddc\u0d9c\u0dd0\u0dbd\u0db4\u0dda'
            },
            ofertaAgreement: {
                notChecked: '\u0db1\u0dd3\u0dad\u0dd2\u0020\u0dc3\u0db8\u0d9f\u0020\u0d94\u0db6\u0dda\u0020\u0d91\u0d9a\u0d9f\u0dad\u0dcf\u0dc0\u0020\u0dad\u0dc4\u0dc0\u0dd4\u0dbb\u0dd4\u0020\u0d9a\u0dbb\u0db1\u0dca\u0db1'
            },
        },

        options: {
            regType: "phone",
            bonusType: "casino",

            tabButtonClass: ".tab",
            formEmailClass: ".form-email",
            formPhoneClass: ".form-phone",
            buttonActionClass: ".button-action",
            emailInputClass: ".email-input",
            passwordInputClass: ".password-input",
            passwordInput2Class: ".password-input2",
            phoneInputClass: ".phone-input",
            emailCountry: ".email-country",
            phonePrefixSelect: ".phone-prefix__select",
            phonePrefixCode: ".phone-prefix__code",
            phoneCurrency: ".phone-currency",
            emailCurrency: ".email-currency",
            phonePrefixFlag: ".phone-prefix__flag",

            emailErrorClass: ".email-error",
            passwordErrorClass: ".password-error",
            password2ErrorClass: ".password2-error",
            phoneErrorClass: ".phone-error",
            ofertaAgreementInputId: "#oferta-agreement",
        },
        
    });

    start({ phones: window.phones, translations: translations });
});